<template>
  <div>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="balancelist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#" width="80px"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="total" :label="$t('jj.xyedzj')"></el-table-column>
      <el-table-column prop="canuse" :label="$t('jj.kyxyed')"></el-table-column>
      <el-table-column prop="used" :label="$t('jj.ysyxyed')"></el-table-column>
      <el-table-column prop="freeze" :label="$t('jj.djxyed')">
      </el-table-column>
      <el-table-column prop="unit" :label="$t('jj.eddw')"></el-table-column>
      <el-table-column :label="$t('caozuo')">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 编辑基金分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="editform" label-width="100px" ref="editform">
        <el-form-item :label="$t('jj.xyed')">
          <el-input v-model="editform.total"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.cplx')">
          <el-select v-model="editform.type">
            <el-option label="USDT" value="usdt"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1
      },
      balancelist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      // 编辑分类数据对象
      editform: {
        total: '',
        id: '',
        type: 'usdt'
      }
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/fund/credit', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.balancelist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/fund/editcredit/id/' + id)
      this.editform.total = data.data.total
      this.editform.id = data.data.id
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/fund/editcredit',
          this.editform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.editclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editform.resetFields()
      this.editdialogvisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont {
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
